import React, { useState } from 'react';
import axios from "axios";
import { FormControl, makeStyles, Theme } from '@material-ui/core';

import FormInput from '../components/form-input';
import { globalStore } from '../stores/global';
import Button from '../components/custom-button';
import { t } from '../i18n';
import { useAuth } from '../services/auth-check';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        minWidth: '300px',
        maxWidth: '300px',
    }
}));

type SessionInfo = {
    username: string,
    password: string,
}


const defaultState: SessionInfo = {
    username: '',
    password: '',
}

function LoginPage() {
    const classes = useStyles();
    const history = useHistory();

    const [session, setSessionInfo] = useState<SessionInfo>(defaultState);
    const [required, setRequired] = useState<any>({} as any);

    const handleSubmit = () => {
        if (!session.username) {
            setRequired({ ...required, username: 'Username is required!' });
            return;
        }
        if (!session.password) {
            setRequired({ ...required, password: 'Password is required!' });
            return;
        }
        globalStore.showLoading();
        axios({
            method: 'POST',
            url: `https://codecircle.co/api/v1.0/login`,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                username: session.username,
                password: session.password
            }
        })
            .then((response: any) => {
                console.log(response);
                if (response) {
                    sessionStorage.setItem("UserData", `${JSON.stringify(response.data)}`);
                    axios({
                        method: 'POST',
                        url: `https://codecircle.co/api/v1.0/rooms`,
                        headers: {
                            'content-type': 'application/json'
                        },
                        data: {
                            id: response && response.data.id,
                            type: response && response.data.type
                        }
                    })
                        .then(({ data }: any) => {
                            if (data) {
                                const rooms = data.rooms.map((room: string) => ({ label: room, value: room }));
                                console.log(rooms);
                                sessionStorage.setItem("Rooms", `${JSON.stringify(rooms)}`);
                                history.push('/');
                            }
                        }).finally(() => {
                            globalStore.stopLoading();
                        });
                }
            }, (error: any) => {
                globalStore.showToast({
                    type: 'msg',
                    message: t('toast.wrong_username', { reason: error.message }),
                })
            }).finally(() => {
                globalStore.stopLoading();
            });
    }

    const [authorized, redirect] = useAuth('LOGIN');

    if (!authorized) {
        redirect();
        return null;
    }

    return (
        <div className={`flex-container login`}>
            <div className="logo"></div>
            <div className="card">
                <FormControl className={classes.formControl}>
                    <FormInput Label={'Username'} value={session.username} onChange={
                        (val: string) => {
                            setSessionInfo({
                                ...session,
                                username: val
                            });
                        }}
                        requiredText={required.username}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <FormInput Label={'Password'} value={session.password} onChange={
                        (val: string) => {
                            setSessionInfo({
                                ...session,
                                password: val
                            });
                        }}
                        type="password"
                        requiredText={required.password}
                    />
                </FormControl>
                <Button name={'Login'} onClick={handleSubmit} />
            </div>
        </div>
    )
}
export default React.memo(LoginPage);

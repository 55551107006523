import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/custom-button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes, roleTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';
import axios from 'axios';
import { useAuth } from '../services/auth-check';
import _ from 'lodash';

const Rooms = JSON.parse(sessionStorage.getItem("Rooms") as string);

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: '240px',
    maxWidth: '240px',
  }
}));

type SessionInfo = {
  roomName: any
  roomType: number
  yourName: string
  role: string
}

const defaultState: SessionInfo = {
  roomName: '',
  roomType: 1,
  role: '',
  yourName: '',
}

function HomePage() {
  const UserData = JSON.parse(sessionStorage.getItem("UserData") as string);
  const Rooms = JSON.parse(sessionStorage.getItem("Rooms") as string);

  document.title = t(`home.short_title.title`)
  const classes = useStyles();

  const history = useHistory();

  const handleSetting = (evt: any) => {
    history.push({ pathname: `/device_test` });
  }

  const {
    HomeBtn
  } = usePlatform();

  const ref = useRef<boolean>(false);

  React.useEffect(() => {
    if (!UserData && !Rooms) {
      return;
    }
  }, [UserData, Rooms]);


  useEffect(() => {
    return () => {
      ref.current = true;
    }
  }, []);

  const [session, setSessionInfo] = useState<SessionInfo>(defaultState);
  const [open, setOpen] = React.useState(false);

  const handleSubmit = () => {
    console.log(session.roomName);
    // const room = _.find(Rooms, (roomName) => {console.log(roomName.label)});
    // console.log(room)
    const roomValue = Rooms[session.roomName];
    console.log(roomValue);
    session.roomName = roomValue.value;
    session.roomType = 1;
    //return;

    if (!roomTypes[session.roomType]) return;
    const path = roomTypes[session.roomType].path
    const payload = {
      uid: genUid(),
      rid: `${session.roomName}`,
      role: UserData && UserData.type,
      roomName: session.roomName,
      roomType: 1,
      video: 0,
      audio: 1,
      chat: 1,
      account: UserData && UserData.name,
      rtmToken: '',
      boardId: '',
      linkId: 0,
      sharedId: 0,
      lockBoard: 0,
      grantBoard: 0,
    }
    console.log(payload)
    ref.current = true;
    globalStore.showLoading();
    roomStore.loginAndJoin(payload).then(() => {
      history.push(`/classroom/small-class`);
    }).catch((err: any) => {
      if (err.reason) {
        globalStore.showToast({
          type: 'rtmClient',
          message: t('toast.rtm_login_failed_reason', { reason: err.reason }),
        })
      } else {
        globalStore.showToast({
          type: 'rtmClient',
          message: t('toast.rtm_login_failed'),
        })
      }
      console.warn(err);
    })
      .finally(() => {
        ref.current = false;
        globalStore.stopLoading();
      })
  }

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    sessionStorage.removeItem("UserData");
    sessionStorage.removeItem("Rooms");
    history.push('/login');
    setOpen(false);
  };


  const [authorized, redirect] = useAuth('HOME');

  if (!authorized) {
    redirect();
    return null;
  }

  return (
    <>
      <Icon className="icon-exit home-" onClick={(evt: any) => {
        handleClick();
      }} />
      <Dialog
        className="logout-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} name={'Logout'} />
          <Button onClick={handleClose} name={'Close'} />
        </DialogActions>
      </Dialog>
      {Rooms &&
        <div className={`flex-container home-cover-web`}>
          {isElectron ? null :
            <div className="web-menu">
              <div className="web-menu-container">
                <div className="short-title"></div>
                <div className="setting-container">
                  <Icon className="icon-setting" onClick={handleSetting} />
                </div>
              </div>
            </div>
          }
          <div className="custom-card">
            <div className="flex-item cover">
              {isElectron ?
                <>
                  <div className={`short-title ${globalStore.state.language}`}>
                    <span className="title">{t('home.short_title.title')}</span>
                    <span className="subtitle">{t('home.short_title.subtitle')}</span>
                  </div>
                  <div className={`cover-placeholder ${t('home.cover_class')}`}></div>
                  <div className='build-version'>{t("build_version")}</div>
                </>
                : <div className={`cover-placeholder-web ${t('home.cover_class')}`}></div>
              }
            </div>
            <div className="flex-item card">
              <div className="position-top card-menu">
                <HomeBtn handleSetting={handleSetting} />
              </div>
              <div className="position-content flex-direction-column">
                <FormControl className={classes.formControl}>
                  <FormSelect
                    Label={t('home.room_type')}
                    value={session.roomName}
                    onChange={(evt: any) => {
                      console.log(evt)
                      setSessionInfo({
                        ...session,
                        roomName: evt.target.value
                      });
                    }}
                    items={Rooms && Rooms?.map((it: any) => ({
                      value: it.value,
                      text: it.label,
                    }))}
                  />
                </FormControl>
                <Button name={t('home.room_join')} onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default React.memo(HomePage);

import { useHistory } from "react-router-dom";

type LoggedInPaths = "HOME";
type LoggedOutPaths = "LOGIN";

export const useAuth = (
    currentPath: LoggedInPaths | LoggedOutPaths
): [boolean, (roomCode?: string) => void] => {
    let authorized = false;
    let redirectPath = "/";
    const history = useHistory();
    const user = JSON.parse(sessionStorage.getItem("UserData") as string);
    
    const redirect = (path: string) => {
        return (roomCode?: string) => {
            history.push(roomCode && path === '/login' ? `${path}/${roomCode}` : path);
        };
    };

    if (!user) {
        authorized = ["LOGIN"].includes(currentPath);
        redirectPath = "/login";

    }

    if (user) {
        authorized = ["HOME"].includes(currentPath);
        redirectPath = "/";
    }

    //console.log(user, currentPath, authorized);

    return [authorized, redirect(redirectPath)];

};
